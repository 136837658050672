import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { clearAllCookies } from '../services/auth';

const instance = axios.create({
  baseURL: TTP_API_URL,
  headers: {
    Accept: 'application/json;app=Sending',
  },
});
// intercept token expired case
instance.interceptors.response.use(response => response
  , (error) => {
    if (error?.response?.status == 401) {
      clearAllCookies()
      window.location = '/';
    }
    return Promise.reject(error);
  });

export default instance;
