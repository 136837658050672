import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import {fetchMembrshipClientsList} from "../../../../actions/thunks/campaign";
import {fetchMembrshipClientsStats} from "../../../../actions/thunks/campaign";
import {
  setCurrentEmailCampaignData, setOeccbbFilters
} from "../../../../actions/actions/currentCampaign";

@connect((store) => {
  return {
    recipients: store.campaigns.recipients,
    formulas: store.campaigns.list.formulas,
    eventFilters: store.currentCampaign.email.eventFilters,
  }
}, (dispatch) => {
  return {
    setCurrentEmailCampaignData: (data) => dispatch((setCurrentEmailCampaignData(data))),
    fetchMembrshipClientsList: (data) => dispatch(fetchMembrshipClientsList(data)),
    fetchMembrshipClientsStats: (data) => dispatch(fetchMembrshipClientsStats(data)),
    setOeccbbFilters: (data) => dispatch(setOeccbbFilters(data)),
  }
})
export default class OeccbbMembersFilters extends Component {

  constructor(props) {
    super(props);
    this.yearsOptions = [{value: 2025, label: 2025}, {value: 2024, label: 2024},{value: 2023, label: 2023},{value: 2022, label: 2022},{value: 2021, label: 2021}, ]
    this.statusOptions = [{value: 'PAID', label: _('paid')},{value: 'PENDING', label: _('pending')}]
    this.sourceOptions = [{value: 'UA', label: 'UA'},{value: 'OECCBB', label: 'OECCBB'}]
    this.state = {
      year: 2024,
      source: '',
      formulas: [],
      statut: 'PAID',
    }
  }

  setFiltersFromEventFilters = () => {
    const { eventFilters } = this.props;
    if (eventFilters && eventFilters.length) {
      const newState = { ...this.state };
      eventFilters.forEach(filter => {
        switch (filter.property) {
          case 'memberShipOrder.data':
            newState.formulas = filter.value;
            break;
          case 'memberShipOrder.year':
            newState.year = filter.value;
            break;
          case 'memberShipOrder.status':
            newState.statut = filter.value;
            break;
          case 'memberShipOrder.source':
            newState.source = filter.value;
            break;
        }
      });
      this.setState(newState);
    }
  }


  updateOeccbbFilters = () => {
    const { year, source, formulas, statut } = this.state;
    const filters = [
      { property: 'memberShipOrder.data', operator: 'in', value: formulas },
      { property: 'memberShipOrder.year', operator: 'eq', value: year },
      { property: 'memberShipOrder.status', operator: 'eq', value: statut },
      { property: 'memberShipOrder.source', operator: 'eq', value: source }
    ];
    this.props.setOeccbbFilters(filters);
  }

  handleYearChange = (selected) => {
    this.setState({ year: selected }, () => {
      this.updateOeccbbFilters();
      this.props.setCurrentEmailCampaignData({...this.state})
      this.props.fetchMembrshipClientsList({...this.state});
    });
  }

  handleStatusChange = (selected) => {
    this.setState({ statut: selected }, () => {
      this.updateOeccbbFilters();
      this.props.setCurrentEmailCampaignData({...this.state})
      this.props.fetchMembrshipClientsList({...this.state});
    });
  }

  handleSourceChange = (selected) => {
    this.setState({ source: selected }, () => {
      this.updateOeccbbFilters();
      this.props.setCurrentEmailCampaignData({...this.state})
      this.props.fetchMembrshipClientsList({...this.state});
    });
  }

  handleFormulasChange = (selected) => {
    this.setState({ formulas: selected }, () => {
      this.updateOeccbbFilters();
      this.props.setCurrentEmailCampaignData({...this.state})
      this.props.fetchMembrshipClientsList({...this.state});
    });
  }

  handleContactTypeChange = (selected) => {
    this.props.handleSetContactType(selected)
  }

  componentDidUpdate(prevProps, prevState) {
    let { filter } = this.props.recipients;
    let state = this.state;
    if (filter !== prevProps.recipients.filter && this.props.contactType === 'OECCBB_MEMBERS' ) {
      this.props.fetchMembrshipClientsList(state);
    }
    if (this.props.contactType !== prevProps.contactType && this.props.contactType === 'OECCBB_MEMBERS'){
      this.props.fetchMembrshipClientsList(state);
      this.props.fetchMembrshipClientsStats(state);
      this.updateOeccbbFilters();
    }
  }

  componentDidMount() {
    this.setFiltersFromEventFilters();
    this.handleContactTypeChange(this.props.contactType);
    if (this.props.contactType === 'OECCBB_MEMBERS'){
      this.props.fetchMembrshipClientsList({
        year: 2024,
        source: '',
        formulas: [],
        status: 'PAID'
      });
      this.props.fetchMembrshipClientsStats({
        year: 2024,
        source: '',
        formulas: [],
        status: 'PAID'
      });
      this.updateOeccbbFilters();
    }
  }


  render() {
    const {year, source, formulas, statut} = this.state;
    const { handleSetContactType, contactType } = this.props;
    const formulasOptions = this.props.formulas?.map((item, index) => ({label: item.formula, value: index + 1})) ?? [];
    const contactTypes = [{label: _('Default'), value: 'Default'}, {label: _('oeccbbMembers'), value: 'OECCBB_MEMBERS'}]
    return (
        <div>
          <div className='row'>
            <div className="columns small-6 columns-padding">
              <span className="param__title small-10">{_('contactType')}</span>
              <div className="small-12">
                <TTPSelect
                  simple={true}
                  notClearable={true}
                  values={contactType}
                  placeholder={_('contactType')}
                  options={contactTypes}
                  onChange={this.handleContactTypeChange}/>
              </div>
            </div>
          </div>
          <div className="columns small-6 columns-padding">
          </div>
          {contactType !== 'Default' ?
            <>
              <div className="row">
                <div className="columns small-6 columns-padding">
                  <div className="sidebar__form-control">
                    <h1 className="param__title">{_('year')}</h1>
                    <TTPSelect
                      simple={true}
                      notClearable={true}
                      values={year}
                      placeholder={_('Year')}
                      options={this.yearsOptions}
                      onChange={this.handleYearChange}/>
                  </div>
                </div>
                <div className="columns small-6 columns-padding">
                  <div className="sidebar__form-control">
                    <h1 className="param__title">{_('Status')}</h1>
                    <TTPSelect
                      simple={true}
                      notClearable={true}
                      values={statut}
                      placeholder={_('Status')}
                      options={this.statusOptions}
                      onChange={this.handleStatusChange}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns small-6 columns-padding">
                  <div className="sidebar__form-control">
                    <h1 className="param__title">{_('source')}</h1>
                    <TTPSelect
                      simple={true}
                      values={source}
                      placeholder={_('Source')}
                      options={this.sourceOptions}
                      onChange={this.handleSourceChange}/>
                  </div>
                </div>
                <div className="columns small-6 columns-padding">
                  <div className="sidebar__form-control">
                    <h1 className="param__title">{_('Formulas')}</h1>
                    <TTPSelect
                      notClearable={true}
                      values={formulas}
                      placeholder={_('select_formule')}
                      options={formulasOptions}
                      onChange={this.handleFormulasChange}/>
                  </div>
                </div>
              </div>
            </> : null
        }
        </div>
    );
  }
}
