import React, {Component} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {readNotification} from "thunks";
import {setLanguage} from "actions";
import {TTP_HOME_URL, TTP_PORTAL_URL, APP_ENV} from "Config";
import {STEPS} from "Common";
import _ from "i18n";
import {Header} from "tamtam-components";
import {clearAllCookies} from "../../services/auth";
import {getMenuLogo} from "../../services/misc";

const Link = ({href, children}) => {
  const history = useHistory();
  const onClick = () => {
    history.push(href);
  };
  return <>
    {React.cloneElement(children, {onClick: onClick})}
  </>;
};

class Menu extends Component {

  logout(e) {
    e.preventDefault();
    clearAllCookies();

    window.location = "/";
  }

  handleNotificationClick = (notification) => {
    this.props.dispatch(readNotification(notification.id));
  };

  changeLng = (lng) => {
    this.props.dispatch(setLanguage(lng));
  }

  render() {
    const {loggedAs, user, currentClient} = this.props.auth;
    const {step, application, notifications, lng} = this.props;

    let hideMenu = ([STEPS["MANAGE_THEME"], STEPS["THEME_PREVIEW"], STEPS["SELECT_NEWS"], STEPS["MANAGE_TEXT_MODEL"], "HIDE_MENU"].indexOf(step) !== -1);
    const showEmailing = application === "EMAILING" && loggedAs === "ADMIN";
    const showSms = application === "SMS" && loggedAs === "ADMIN" && user && user.type === "ADMIN_SUPERVISOR";
    const showAdministration = user && (user.type === "ADMIN_SUPERVISOR" || user.type === "ADMIN");

    /**   Right Icons */
    let rightIcons = {
      home: {activated: true, icon: "Portal", url: TTP_PORTAL_URL},
      profile: {
        activated: true,
        icon: "Profile",
        url: `${TTP_HOME_URL}profile`
      },
      ebox: {activated: true, icon: "Ebox", url: "/e_box"},
      search: {activated: false, icon: "Search"},
      notifs: {activated: true, icon: "Notifs"},
      apps: {activated: true},
      faq: {activated: true, icon: "Help"},
    };

    /**   App */
    const isLoggedOut = !user;
    const app = getMenuLogo(application, isLoggedOut);

    /**    Setting  */
    let settings = [];
    if (showEmailing)
      settings.push(
        {label: _("settings"), url: "/default-settings"},
        {label: _("approval"), url: "/approvers-setting"},
        {label: _("theme categories"), url: "/theme-category"},
        {label: _("users"), url: "/settings"}
      );
    if (showAdministration)
      settings.push({label: _("administration"), url: "/administration"});
    if (showSms)
      settings.push({
        label: _("sms credit validation"),
        url: "/sms-credit-validation"
      });

    const formatIntro = (content) => {
      return content && (content.length > 100 ? content.substr(0, 100) + "..." : content);
    };

    /**   notifications */
    if (notifications && notifications.length > 0)
      notifications.forEach(notification => {
        notification.titleEn = notification.subjectEn;
        notification.titleFr = notification.subjectFr;
        notification.titleNl = notification.subjectNl;
        notification.introEn = formatIntro(notification.contentEn);
        notification.introFr = formatIntro(notification.contentFr);
        notification.introNl = formatIntro(notification.contentNl);
        notification.isRead = notification.status === "READ";
      });

    if (!user)
      return <Header
        auth={{navCommunity: null, user: null}}
        lng={lng}
        app={app}
        onLanguageChange={this.changeLng}
      />;

    if (hideMenu)
      return null;

    return (<div id={"menu-container"}>
        <Header
          auth={{
            ...this.props.auth,
            navCommunity: currentClient,
            user: {...user, communities: user.clients}
          }}
          lng={lng}
          notifications={notifications}
          settings={settings}
          rightIcons={rightIcons}
          onLogoutClick={this.logout.bind(this)}
          onLanguageChange={this.changeLng}
          handleNotificationClick={this.handleNotificationClick}
          app={app}
          Link={Link}
          env={APP_ENV}
        /></div>
    );
  }
}

const mapStateToProps = (store) => ({
  auth: store.auth,
  step: store.params.step,
  currentNavPage: store.params.currentNavPage,
  currentSideBar: store.currentCampaign.sms.currentSideBar,
  application: store.params.application,
  notifications: store.notifications.web.items,
  lng: store.params.lng
});
export default connect(mapStateToProps)(Menu);
