import React, { Component } from "react";
import { connect } from "react-redux";
import {
  SVG_DOUBLE_ARROW_LEFT_ICON,
  SVG_DOUBLE_ARROW_RIGHT_ICON,
  SVG_SEND_COMPANION_ICON,
} from "../../../services/svgIcones";
import _ from "i18n";
import {
  setCurrentEmailCampaignData,
  setTitleSelection,
  setCurrentCampaignTheme,
} from "../../../actions/actions/currentCampaign";
import { TTPModal } from "../../common/Modal/TTPModal";
import { ucFirst } from "../../../services/common";
import { NotificationManager } from "react-notifications";
import { onError, onSuccess } from "../../../services/utils";
import {
  fetchRecipientsCriteria,
  persistRecipientsCriteria,
} from "../../../actions/thunks/campaign";
import CheckBoxBtn from "../../common/CheckBoxBtn";
import {
  setCurrentDndTheme,
} from "../../../actions/actions/theme";
const mapStateToProps = (state) => ({
  currentStep: state.currentCampaign.email.currentSideBar,
  updating: state.currentCampaign.email.updating,
  isTested: state.currentCampaign.email.isTested,
  uaRecipientType: state.currentCampaign.email.uaRecipientType,
  uaFilters: state.currentCampaign.email.uaFilters,
  manualRecipients: state.currentCampaign.email.manualRecipients,
  approvals: state.approval.list.items,
  currentTheme: state.themes.currentDndTheme,
  templates: state.themes.list.items,

});
const mapDispatchToProps = (dispatch) => ({
  setCurrentCampaign: (data) => dispatch(setCurrentEmailCampaignData(data)),
  setTitleSelection: (data) => dispatch(setTitleSelection(data)),
  persistRecipientsCriteria: (data) =>
    dispatch(persistRecipientsCriteria(data)),
  fetchRecipientsCriteria: () => dispatch(fetchRecipientsCriteria()),
  setCurrentCampaignTheme: (theme) => dispatch(setCurrentCampaignTheme(theme)),
  setCurrentDndTheme: (theme) => dispatch(setCurrentDndTheme(theme)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class StepsFooter extends Component {
  constructor(props) {
    super(props);
  }

  handleNextStep = (currentStep) => {
    const {
      isManualRecipientNull,
      typeWithNoRecipients,
      isMaxRecipient,
      setCurrentCampaign,
      currentPage,
      setCurrentDndTheme,
      setCurrentCampaignTheme,
      currentTheme,
      templates,
    } = this.props;

    switch (currentStep) {
      case "PARAMS":
        return setCurrentCampaign({
          currentSideBar: "RECIPIENTS",
        });
      case "RECIPIENTS":
        if (isManualRecipientNull) {
          NotificationManager.info(_("manualRecipientNullWarning"));
          break;
        } else if (typeWithNoRecipients) {
          NotificationManager.info(
            _("choose_recipients_warning") +
              ": " +
              ucFirst(_(typeWithNoRecipients).toLowerCase()),
          );
          break;
        } else if (isMaxRecipient) {
          NotificationManager.info(_("max_recipients_alert"));
          break;
        } else {
          if (currentPage === "RICH_TEXT_PAGE") {
            return setCurrentCampaign({ currentSideBar: "TEST" });
        } else {
            return setCurrentCampaign({ currentSideBar: "TEMPLATE_ADAPTATION" });
        }
        }
        case "TEMPLATE_ADAPTATION":
            let editor = this.editor || unlayer;
            editor.exportHtml(({ html, design }) => {
              let theme = {
                ...currentTheme,
                design,
                content: html,
              };
              if (templates && templates.length > 0) {
                theme.id = templates[0].id;
              }
              setCurrentDndTheme(theme);
              setCurrentCampaignTheme(theme);
              setCurrentCampaign({ currentSideBar: "TEST" });
            });
          break;
      case "TEST":
        return setCurrentCampaign({ currentSideBar: "APPROVAL" });
      case "APPROVAL":
        return setCurrentCampaign({ currentSideBar: "SEND" });
    }
  };

  handlePreviousStep = (currentStep) => {
    const { setCurrentCampaign,currentPage } = this.props;
    
    switch (currentStep) {
      case "PARAMS":
        return setCurrentCampaign({ currentSideBar: "PERIODS" });
      case "RECIPIENTS":
        return setCurrentCampaign({ currentSideBar: "PARAMS" });
      case "TEMPLATE_ADAPTATION":
        return setCurrentCampaign({ currentSideBar: "RECIPIENTS" });
      case "TEST":
        if (currentPage === "RICH_TEXT_PAGE") {
          return setCurrentCampaign({ currentSideBar: "RECIPIENTS" });
      } else {
          return setCurrentCampaign({ currentSideBar: "TEMPLATE_ADAPTATION" });
      }
      case "APPROVAL":
        return setCurrentCampaign({ currentSideBar: "TEST" });
      case "SEND":
        return setCurrentCampaign({ currentSideBar: "APPROVAL" });
    }
  };

  render() {
    const {
      currentStep,
      testingEnabled,
      updating,
      allowSend,
      disabled,
      isTested,
      uaRecipientType,
      uaFilters,
      isManualRecipientNull,
      isPlanPersonalized,
      approvals,
      currentPage,
    } = this.props;

    var isApproval = approvals && Object.keys(approvals).length > 0;

    return (
      <div
        className="footer"
        style={{
          "--bottom": ["TEST", "APPROVAL", "SEND","PARAMS","RECIPIENTS"].includes(currentStep)
            ? "0"
            : "0",
        }}
      >
        <div className="footer-btns">
        {(currentStep !== "PARAMS" ||
          (currentStep === "PARAMS" && isPlanPersonalized && currentPage !== "RICH_TEXT_PAGE")) && (
          <button
            className="return-btn"
            onClick={() => this.handlePreviousStep(currentStep)}
          >
            <span>{SVG_DOUBLE_ARROW_LEFT_ICON}</span>
            {_("back")}
          </button>
        )}
          <div className="footer-btns_right">
            {currentStep === "TEMPLATE_ADAPTATION" && (
              <button
                className="right-btn"
                onClick={() => this.handleNextStep(currentStep)}
              >
                {_("continue")}
                <span>{SVG_DOUBLE_ARROW_RIGHT_ICON}</span>
              </button>
            )}

            {currentStep === "TEST" && (
              <button
                className="sendTest-btn"
                disabled={!testingEnabled}
                onClick={() => !updating && this.props.handleTestClick()}
              >
                {updating
                  ? _("Processing...")
                  : isTested === 0
                    ? _("sendTest")
                    : _("resendTest")}
              </button>
            )}

            {currentStep === "APPROVAL" && (
              <button
                className="sendTest-btn"
                disabled={!testingEnabled}
                onClick={() => !updating && this.props.handleApprovalClick()}
              >
                {updating
                  ? _("Processing...")
                  : isApproval
                    ? _("resendApproval")
                    : _("send_approval")}
              </button>
            )}

            {currentStep !== "SEND" && currentStep !== "TEMPLATE_ADAPTATION" ? (
              <button
                className="right-btn"
                disabled={disabled}
                onClick={() => {
                  this.handleNextStep(currentStep);
                }}
                style={{
                  marginLeft:
                    currentStep !== "TEST" &&
                    currentStep !== "RECIPIENTS" &&
                    currentStep === "APPROVAL"
                      ? "auto"
                      : currentStep === "RECIPIENTS" ||
                          currentStep === "APPROVAL"
                        ? "1rem"
                        : "",
                }}
              >
                {currentStep === "TEST"
                  ? isTested === 1
                    ? _("resume")
                    : _("ignore")
                  : _("resume")}
                <span>{SVG_DOUBLE_ARROW_RIGHT_ICON}</span>
              </button>
            ) : (
              currentStep === "SEND" && (
                <button
                  className="send-btn"
                  disabled={!allowSend}
                  onClick={() =>
                    allowSend
                      ? !updating && this.props.handleShowDialog()
                      : null
                  }
                >
                  {updating ? _("Processing...") : _("Send campaign")}
                  <span>{SVG_SEND_COMPANION_ICON}</span>
                </button>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
